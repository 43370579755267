import {
  AUSTRALIA_COUNTRY_CODE,
  COUNTRIES_WITH_METRIC,
  COUNTRY_CODES,
  CURRENT_TIME_OPTION_DISPLAY,
  DATAFEED_MEMBER,
  KM_TO_MILES,
  OUTDATED_PRICE_DISPLAY,
  OWNER_MEMBER,
  REGEX,
  REGULAR_MEMBER,
  REQUIRED_ADDRESS_FIELDS,
  TREND_VALUES,
} from '../../common/constants';
import PriceUnits from '../../common/constants/priceUnits';
import formatDiscount from './formatDiscount';

function isWindowAvailable() {
  return typeof window !== 'undefined';
}

const hasValidAddress = address => (REQUIRED_ADDRESS_FIELDS.every(field => !!address[field]));

function isValidUSPostal(postalCode) {
  return REGEX.POSTALCODE.USA.test(postalCode);
}

function isValidCAPostal(postalCode) {
  return REGEX.POSTALCODE.CAN.test(postalCode);
}

function isValidPostal(postalCode) {
  return isValidUSPostal(postalCode) || isValidCAPostal(postalCode);
}

const hasValidPostalCodeForCountry = (postalCodeString, country) => {
  switch (country) {
    case 'CA':
    case 'Canada':
      return isValidCAPostal(postalCodeString);
    default:
      return isValidUSPostal(postalCodeString);
  }
};

const getPostalCodeExampleForCountry = (country) => {
  switch (country) {
    case 'CA':
    case 'Canada':
      return '"A#A #A#"';
    default:
      return '"#####" or "#####-####"';
  }
};

const convertToGBApiCountry = (country) => {
  // Note: can likely remove Australia when we know we won't get any Australian stations from the server
  switch (country) {
    case COUNTRY_CODES.CANADA:
    case 'Canada':
      return 'CAN';
    case AUSTRALIA_COUNTRY_CODE:
    case 'Australia':
      return 'AUS';
    default:
      return 'USA';
  }
};

const convertToCountryCode = (country) => {
  switch (country) {
    case 'CAN':
    case 'Canada':
      return COUNTRY_CODES.CANADA;
    case 'AUS':
    case 'Australia':
      return AUSTRALIA_COUNTRY_CODE;
    default:
      return COUNTRY_CODES.USA;
  }
};

function isValidMemberName(memberName) {
  return REGEX.USER_NAME.test(memberName);
}

const getOrdinalIndicator = (number) => {
  const lastNumber = number.toString().substr(-1);

  switch (lastNumber) {
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return 'th';
  }
};

/**
 * Formats a passed in number to add commas where necessary.
*/
const numberFormatter = (numberString) => {
  if (numberString.length < 4) {
    return numberString;
  }

  const numLeadingDigits = numberString.length % 3;
  const formattedNumber = numberString.slice(numLeadingDigits).match(/.{1,3}/g).join(',');

  if (numLeadingDigits > 0) {
    const startingString = numberString.slice(0, numLeadingDigits);
    return startingString.concat(',', formattedNumber);
  }

  return formattedNumber;
};

const kmToMiles = km => km / KM_TO_MILES;
const milesToKm = miles => miles * KM_TO_MILES;

const distanceFormatter = (distanceInMi, country) => {
  if (country && COUNTRIES_WITH_METRIC.indexOf(country) > -1) {
    return `${milesToKm(distanceInMi).toFixed(1)}km`;
  }

  return `${distanceInMi.toFixed(2)}mi`;
};

const priceFormatter = (price = 0.0, priceUnit = PriceUnits.DollarsPerGallon) => {
  if (price <= 0.0) {
    return OUTDATED_PRICE_DISPLAY;
  }

  const formattedPrice = priceUnit === PriceUnits.CentsPerLiter ? `${price.toFixed(1)}¢` : `$${price.toFixed(2)}`;
  return formattedPrice;
};

const getDateDiffInSeconds = (inputDate) => {
  // throw error if not expected input type
  if (typeof inputDate !== 'string') throw new TypeError('Input must be a string');
  // if empty, return parameter back to consumer favoring purity.
  if (inputDate === '') return inputDate;

  return Math.floor(((new Date() - new Date(inputDate)) / 1000));
};

const getMemberPriceStampInfoFromMemberName = (memberName) => {
  switch (memberName?.toLowerCase()) {
    case 'datafeed':
    case 'data-feed':
      return DATAFEED_MEMBER;
    case 'owner':
      return OWNER_MEMBER;
    default:
      return REGULAR_MEMBER(memberName);
  }
};

const buildTimeSpottedStringFromDate = (date) => {
  const dateHours = date.getHours();
  const dateMinutes = date.getMinutes();
  const hours = dateHours % 12 === 0 ? 12 : dateHours % 12;
  const minutes = dateMinutes < 10 ? `0${dateMinutes}` : dateMinutes;
  const timeOfDay = dateHours >= 12 ? 'PM' : 'AM';

  return `${hours}:${minutes} ${timeOfDay}`;
};

const generateTimeSpottedList = () => {
  const currentTime = new Date();
  const nextDateInList = currentTime;
  const timeList = [];
  const totalOptionsInList = 24; // = HOURS_DESIRED / INCREMENT_FACTOR = 12 / 0.5

  // Add the current time as our first selection
  timeList.push({
    key: 1,
    label: CURRENT_TIME_OPTION_DISPLAY,
    value: currentTime.toISOString(),
  });

  // Our next selection should be the closest 30 minute increment to the current time. Zero out seconds value, too
  nextDateInList.setMinutes(nextDateInList.getMinutes() - (nextDateInList.getMinutes() % 30));
  nextDateInList.setSeconds(0, 0);
  timeList.push({
    key: 2,
    label: buildTimeSpottedStringFromDate(nextDateInList),
    value: nextDateInList.toISOString(),
  });

  // The remaining selections are 30 minute increments from each other
  for (let i = 2; i <= totalOptionsInList; i += 1) {
    nextDateInList.setMinutes(nextDateInList.getMinutes() - 30);
    timeList.push({
      key: i + 1,
      label: buildTimeSpottedStringFromDate(nextDateInList),
      value: nextDateInList.toISOString(),
    });
  }

  return timeList;
};

const scrollToTop = () => {
  // only scroll to top if window exists and we have no hash in the url
  if (isWindowAvailable() && !window.location.hash) {
    window.scrollTo(0, 0);
  }
};

const pluralizeIfNeeded = (number, word, pluralizedSuffix, removedCharactersFromEnd = 0) => (
  number === 0 || number > 1
    ? word.substring(0, word.length - removedCharactersFromEnd).concat(pluralizedSuffix || 's')
    : word
);

const addZeroIfNeeded = number => (
  number >= 0 && number < 10 ? '0'.concat(number) : number
);

const isMobileWidth = () => window.innerWidth <= 767;

/**
 * Tests if a given object has a value other than null or undefined
 * @param {any} obj - Any JavaScript object
 */
const hasValue = obj => typeof obj !== 'undefined' && obj !== null;

/**
 * Takes an object and returns an object with only the specified properties
 * @param {object} obj - A JavaScript object
 * @param {string[]} keys - An array of desired properties
 */
function pick(obj, keys = []) {
  return keys.reduce((params, k) => (
    Object.assign(params, {
      [k]: obj[k],
    })
  ), {});
}

/**
 * Takes a trend number (1, 0, -1) and converts to a trend word (Up, Stable, Down)
 *
 * @param {number} trendNumber - a number provided from the pricetrends API [-1...1]
 * @returns {string} - Corresponding Trend Word for displaying a trend SVG
 */
const trendToTrendWord = (trendNumber) => {
  let trendWord;
  Object.entries(TREND_VALUES).forEach(([key, value]) => {
    if (value === trendNumber) {
      trendWord = key;
    }
  });

  return trendWord;
};

/**
 * Takes an object and returns an object with only properties that have values other than null
 * or undefined
 * @param {object} obj - A JavaScript object
 */
const compact = obj => Object.keys(obj)
  .filter(k => hasValue(obj[k]))
  .reduce((newObj, k) => ({ ...newObj, [k]: obj[k] }), {});

/**
 * Truncates the middle characters of a string based on length.
 * @param {string} string - string to potentially truncate
 * @param {number} maxSize - Optional. How long a string can be, before truncation. Default is 15.
 * @param {string} truncateWithChar - Optional. When truncating, what string to truncate with
 * @returns {string} truncated username if over max size, or the username as-is
 */
const truncateForDisplay = (string, maxSize = 15, truncateWithChar = '…') => {
  // If the string isn't long enough, no truncation needed.
  if (string.length <= maxSize) {
    return string;
  }

  const bufferChars = Math.floor((maxSize - truncateWithChar.length) / 2);
  const leftoverChars = (maxSize - truncateWithChar.length) % 2;

  const startOfString = string.substr(0, bufferChars + leftoverChars);
  const endOfString = string.substr(string.length - bufferChars, string.length);

  return `${startOfString}${truncateWithChar}${endOfString}`;
};

/**
 * Tests that an email address is valid
 *
 * @param {string} email - an email address to test
 * @returns {boolean} - Whether the email appears to be valid
 */
const testEmail = email => REGEX.EMAIL.test(email);

export const uniqueReducer = (all, one) => (all.includes(one) ? all : all.concat(one));

export const flattenReducer = (all, innerArr) => [...all, ...innerArr];

const isDefined = obj => typeof obj !== 'undefined';

export const mergeReducer = (prop, value) => (itemsMap, item) => ({
  ...itemsMap,
  [item[prop] || item]: isDefined(value) ? value : item,
});

export const mapToReducer = value => mergeReducer(undefined, value);

/**
 * Format string input to a readable date format for UI components: e.g Apr 18 2018
 * @param {string} inputString
 */
const toReadableMonthYear = inputString => new Date(inputString)
  .toDateString()
  .split(' ')
  .slice(1)
  .join(' ');


/**
 * divideAndFloor :: Number -> Number => Number
 * Curried function to allow partial application
 */
const divideAndFloor = dividend => divisor => Math.floor(dividend / divisor);

/**
 * getDateDiffLiteral :: String -> String
 *
 * Returns a string that represents
 * the user friendly difference between the current date and the input param date
 * in minutes, hours, and days between the two.
 * @param {*} reportDate String that represents date
 */
const getDateDiffLiteral = (reportDate) => {
  if (Number.isNaN(Date.parse(reportDate))) throw new TypeError('Invalid type');

  const dateDiffFromNow = getDateDiffInSeconds(reportDate);
  const getDateDiffIn = divideAndFloor(dateDiffFromNow);

  if (dateDiffFromNow >= 86400) {
    const retNumber = getDateDiffIn(86400);
    return retNumber === 1 ? `${retNumber} day ago` : `${retNumber} days ago`;
  }

  if (dateDiffFromNow >= 3600) {
    const retNumber = getDateDiffIn(3600);
    return retNumber === 1 ? `${retNumber} hour ago` : `${retNumber} hours ago`;
  }

  const minsCopy = `${Math.floor(dateDiffFromNow / 60)} mins ago`;

  return dateDiffFromNow < 60 ? 'seconds ago' : minsCopy;
};

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
const shallowEqual = (objA, objB) => {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null
      || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) { // eslint-disable-line no-plusplus
    if (
      !Object.hasOwnProperty.call(objB, keysA[i])
      || !Object.is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
};

const randomBetween = (min = 0, max = 0) => Math.random() * (max - min) + min;

const shuffleArray = (input = []) => {
  const output = [].concat(input);

  // eslint-disable-next-line no-plusplus
  for (let i = output.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [output[i], output[j]] = [output[j], output[i]];
  }

  return output;
};

function debounce(func, wait, immediate) {
  let timeout;

  return function debounced(...args) {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

function getReferrerHost() {
  if (isWindowAvailable()) {
    return document && document.referrer ? document.referrer.match(/:\/\/(.[^/]+)/)[1] : '';
  }

  return '';
}

function getPageYOffset() {
  const pageYOffset = window?.pageYOffset;
  if (typeof pageYOffset !== 'undefined') {
    return pageYOffset;
  }

  // for IE < 9, https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
  const isCSS1Compat = document?.compatMode === 'CSS1Compat';
  const scrollTop = isCSS1Compat ? document.documentElement.scrollTop : document?.body?.scrollTop;
  return scrollTop;
}

function scrollPageY(yOffset) {
  if (typeof yOffset !== 'undefined') {
    window.scroll({ top: yOffset });
  }
}

function isObject(obj) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
}

/**
 * Takes two values and determines if they are the same or not
 * @param {*} val1
 * @param {*} val2
 */
function isSame(val1, val2) {
  if (typeof val1 !== typeof val2) {
    return false;
  }

  if (typeof val1 === 'string') {
    return val1.trim() === val2.trim();
  }

  if (['number', 'boolean'].includes(typeof val1)) {
    return val1 === val2;
  }

  if (!val1) {
    return true;
  }

  return false;
}

function capitalize(str, allWords = false) {
  if (typeof str === 'string' && str.length > 0) {
    if (allWords) {
      const words = str.split(' ').map(word => capitalize(word));
      return words.join(' ');
    }

    return `${str[0].toUpperCase()}${str.substring(1)}`;
  }
  return str;
}

function toCamelCase(str) {
  // eslint-disable-next-line no-unused-vars
  if (str.match(/[-_]+/g)) {
    return str.toLowerCase().replace(/([-_]+[a-zA-Z0-9])/g, match => match[match.length - 1].toUpperCase());
  }
  // If no - or _ is found, assume it is already in camel case so we don't lowercase a proper camelcase capitalization
  return str;
}

function toSnakeCase(str) {
  // eslint-disable-next-line no-unused-vars
  return str.replace(/\.?([A-Z0-9]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');
}

/**
 * Appends the appendedString to the baseString if the appendedString does not already exist in the baseString
 * @param {String} baseString
 * @param {String} appendedString
 */
function appendStringIfUnique(baseString, appendedString) {
  if (baseString.indexOf(appendedString) === -1) {
    return baseString + appendedString;
  }

  return baseString;
}

/**
 * Cleans a string to canonicalize it. This removes any leading & trailing whitespaces, replaces remaining whitespaces
 * with dashes and ensures the string is lowercase.
 * @param {String} str
 */
function canonicalizeString(str, sanitize) {
  const value = str?.trim().replace(/\s+/g, '-').toLowerCase();
  return sanitize ? encodeURI(value) : value;
}

/**
 * Inserts a value from a known mapping into a string
 * @param {String} str The string to have the interpolated value. Interpolated parts of the string are marked by {{}}
 */
function interpolate(str) {
  const map = {
    max_discount: formatDiscount(25),
  };

  return str?.replace(/{{(\w+)}}/g, (m, key) => map[key] || '');
}

function interpolateImageList(str, imageList = []) {
  let targetImageIndex = -1;

  return str?.replace(/{{image}}/g, () => {
    targetImageIndex += 1;
    const image = imageList?.[targetImageIndex];
    if (image) {
      return `<img src="${image.url}" alt="${image.alt}" />`;
    }
    return '';
  });
}

function convertKeyCase(obj, iteratee) {
  if (isObject(obj)) {
    return Object.entries(obj).reduce((hash, [key, value]) => (
      Object.assign(hash, {
        [iteratee(key)]: convertKeyCase(value, iteratee),
      })
    ), {});
  }

  if (Array.isArray(obj)) {
    return obj.map(i => convertKeyCase(i, iteratee));
  }

  return obj;
}

function keysToCamelCase(obj) {
  return convertKeyCase(obj, toCamelCase);
}

function keysToSnakeCase(obj) {
  return convertKeyCase(obj, toSnakeCase);
}

function noop() {
  // No operation performed.
}

function propSumFromArrayOfObjects(arr, prop) {
  return arr.reduce((total, entry) => (entry[prop] ? Number(entry[prop]) + total : total), 0);
}

function propAverageFromArrayOfObjects(arr, prop) {
  return propSumFromArrayOfObjects(arr, prop) / arr.filter(e => !!e[prop]).length;
}

/**
 * Syncs the objects of one array into a base array based on a key property of each object
 * @param {Array} baseArray The base array that will have its objects updated
 * @param {*} updateArray The array containing objects to update or add to baseArray
 * @param {*} keyProp The key property of each object in baseArray and updateArray used for updating or adding
 */
function syncObjectArrays(baseArray, updateArray, keyProp) {
  const getObject = prop => baseArray.find(obj => obj[keyProp] === prop);
  const newObjects = updateArray.filter(obj => !getObject(obj[keyProp]));
  const updatedObjects = updateArray.filter(obj => getObject(obj[keyProp]));

  return [...baseArray, ...newObjects].map(obj => updatedObjects.find(newObj => obj[keyProp] === newObj[keyProp]) || obj);
}

export {
  distanceFormatter,
  hasValidAddress,
  hasValidPostalCodeForCountry,
  getPostalCodeExampleForCountry,
  convertToGBApiCountry,
  convertToCountryCode,
  getOrdinalIndicator,
  numberFormatter,
  priceFormatter,
  getMemberPriceStampInfoFromMemberName,
  generateTimeSpottedList,
  getDateDiffInSeconds,
  getDateDiffLiteral,
  scrollToTop,
  pluralizeIfNeeded,
  addZeroIfNeeded,
  isMobileWidth,
  hasValue,
  pick,
  toReadableMonthYear,
  compact,
  trendToTrendWord,
  truncateForDisplay,
  testEmail,
  shallowEqual,
  randomBetween,
  shuffleArray,
  debounce,
  getReferrerHost,
  getPageYOffset,
  scrollPageY,
  isValidPostal,
  isValidMemberName,
  isObject,
  isSame,
  capitalize,
  toCamelCase,
  toSnakeCase,
  canonicalizeString,
  interpolate,
  interpolateImageList,
  appendStringIfUnique,
  keysToCamelCase,
  keysToSnakeCase,
  noop,
  propSumFromArrayOfObjects,
  propAverageFromArrayOfObjects,
  syncObjectArrays,
  isWindowAvailable,
  milesToKm,
  kmToMiles,
};

export * from './routing';
export * from './formatPhone';
